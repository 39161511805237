import { StyledSurveyStepParagraph } from './RenderSurveyStep.styles';
import { TFunction } from 'i18next';
import { FormItem } from '../../../../molecules/FormItem';
import { RadioGroup } from '../../../../molecules/RadioGroup';
import { Image } from '../../../../atoms/Image';
import { CheckboxGroup } from '../../../../molecules/CheckboxGroup';


interface ComposeBodySurveyFormItemArgs {
  bodyContent: string[];
  populateProjectValueToString: (value: string) => string;
  currentStepName: string;
  t: TFunction<'translation', undefined, 'translation'>;
}

export const composeBodySurveyFormItem = ({
  bodyContent,
  populateProjectValueToString,
  currentStepName,
  t,
}: ComposeBodySurveyFormItemArgs) => {
  const body = bodyContent.map((bodyItem: string, index: number) => {
    let translatedBodyItem = bodyItem;

    if (bodyItem.startsWith('surveyConfig')) {
      translatedBodyItem = t(bodyItem);
    }

    const normalizedBodyItem = populateProjectValueToString(translatedBodyItem);

    return (
      <StyledSurveyStepParagraph key={`${currentStepName}-paragraph-${index}`}>
        {normalizedBodyItem}
      </StyledSurveyStepParagraph>
    );
  });

  return <div key={currentStepName}>{body}</div>;
};

interface OptionType {
  label: string
  value: string;
  disqualify?: boolean;
  deselectAll?: boolean;
}

export const composeRadioListSurveyFormItem = ({
  name,
  formValues,
  options,
  multiselect,
  maxSelected,
  populateProjectValueToString,
  skipOptionsTranslate,
  renderOptionsWithTranslate,
  project,
  currentStepName,
  currentItemCategory,
  currentCategoryStepSpellCheckedConfig,
  currentItemCategoryConfig,
  t,
  createFormItemOptionClickHandler,
  ...rest
}: any) => {
  let parsedOptions: OptionType[] = options;

  const image = currentCategoryStepSpellCheckedConfig?.image;

  try {
    parsedOptions = options.map((option: string | OptionType) => {
      let result = option;

      if (typeof result === 'string') {
        result = JSON.parse(result) as OptionType;
      }

      return {
        ...result,
        label: populateProjectValueToString(result.label)
      };
    }) as OptionType[];
  } catch (error) {
    console.log(error);
    return null
  }

  let translatedOptions = parsedOptions;

  if (!skipOptionsTranslate) {
    translatedOptions = renderOptionsWithTranslate(
      parsedOptions,
      'value',
      `surveyConfig.${project?.type}Steps.${currentStepName}.categories.${currentItemCategory}.options`
    );
  }
  const labelSource = currentCategoryStepSpellCheckedConfig?.translatedTitle
    || currentCategoryStepSpellCheckedConfig?.title;

  const label = labelSource || populateProjectValueToString(t(currentItemCategoryConfig?.title));
  const populatedLabel = populateProjectValueToString(label);

  const getMultiSelectOptions = () => translatedOptions.map((currentOption) => {
    if (
      multiselect && maxSelected
      && formValues && name in formValues
      && Array.isArray(formValues[name])
    ) {
      const formValue = formValues[name];
      const isLengthValid = formValue.length < maxSelected;
      const isSelected = formValue.includes(currentOption.value);

      if (!isLengthValid && !isSelected) {
        return {
          ...currentOption,
          disabled: true
        }
      }
    }

    return currentOption;
  });

  return (
    <>
      {image ? (
        <Image
          // key re-renders the image on url change
          key={image.url}
          src={image.url}
          caption={image.caption}
          alt="Product example"
        />
      ) : null}

      <FormItem
        name={name}
        key={currentStepName}
        label={populatedLabel}
        largeLabel
        labelDisplayBlock
        normalize={(value, prevValue) => {
          if (!multiselect) {
            return value;
          }

          const options = getMultiSelectOptions();
          // `deselectOption` === only that option should be selected, everything else should be deselected
          const deselectOption = options.find((option) => option.deselectAll === true);
          if (Array.isArray(value) && deselectOption) {
            const deselectOptionValue = deselectOption.value;

            const isDeselectOptionSelected = value?.includes(deselectOptionValue) && !prevValue?.includes(deselectOptionValue);
            if (isDeselectOptionSelected) {
              return [deselectOptionValue];
            }

            const isDeselectOptionDeselected = value?.includes(deselectOptionValue) && prevValue?.includes(deselectOptionValue);
            if (isDeselectOptionDeselected) {
              return value.filter((option) => option !== deselectOptionValue);
            }
          }

          return value;
        }}
        {...rest}
      >
        {multiselect ? (
          <CheckboxGroup options={getMultiSelectOptions()} />
        ) : (
          <RadioGroup
            options={translatedOptions}
            onClick={createFormItemOptionClickHandler(currentStepName)}
          />
        )}
      </FormItem>
    </>
  );
};
