/* eslint max-len: ["error", { "code": 130 }] */
import { SOCIAL_NETWORKS_OPTIONS } from './socialNetworkOptions';


const productSurveyConfig = {
  type: 'product',
  steps: [
    {
      step: 'intro',
      body: [
        'surveyConfig.productSteps.intro.body.content1',
        'surveyConfig.productSteps.intro.body.content2',
        'surveyConfig.productSteps.intro.body.content3'
      ]
    },
    {
      step: 'isInterested',
      categories: {
        general: {
          title: 'surveyConfig.productSteps.isInterested.categories.general.title',
          options: [
            { label: 'surveyConfig.general.yes', value: 'yes', disqualify: false },
            { label: 'surveyConfig.general.maybe', value: 'maybe', disqualify: false },
            { label: 'surveyConfig.general.notRightNow', value: 'not-right-now', disqualify: false },
            { label: 'surveyConfig.general.no', value: 'no', disqualify: true },
          ]
        },
        food: {
          title: 'surveyConfig.productSteps.isInterested.categories.food.title',
          options: [
            { label: 'surveyConfig.general.yes', value: 'yes', disqualify: false },
            { label: 'surveyConfig.general.maybe', value: 'maybe', disqualify: false },
            { label: 'surveyConfig.general.notRightNow', value: 'not-right-now', disqualify: false },
            { label: 'surveyConfig.general.no', value: 'no', disqualify: true },
          ]
        },
        drink: {
          title: 'surveyConfig.productSteps.isInterested.categories.drink.title',
          options: [
            { label: 'surveyConfig.general.yes', value: 'yes', disqualify: false },
            { label: 'surveyConfig.general.maybe', value: 'maybe', disqualify: false },
            { label: 'surveyConfig.general.notRightNow', value: 'not-right-now', disqualify: false },
            { label: 'surveyConfig.general.no', value: 'no', disqualify: true },
          ]
        },
        premium: {
          title: 'surveyConfig.productSteps.isInterested.categories.premium.title',
          options: [
            { label: 'surveyConfig.general.yes', value: 'yes', disqualify: false },
            { label: 'surveyConfig.general.maybe', value: 'maybe', disqualify: false },
            { label: 'surveyConfig.general.notRightNow', value: 'not-right-now', disqualify: false },
            { label: 'surveyConfig.general.no', value: 'no', disqualify: true },
          ]
        },
        durable: {
          title: 'surveyConfig.productSteps.isInterested.categories.durable.title',
          options: [
            { label: 'surveyConfig.general.yes', value: 'yes', disqualify: false },
            { label: 'surveyConfig.general.maybe', value: 'maybe', disqualify: false },
            { label: 'surveyConfig.general.notRightNow', value: 'not-right-now', disqualify: false },
            { label: 'surveyConfig.general.no', value: 'no', disqualify: true },
          ]
        },
        convenience: {
          title: 'surveyConfig.productSteps.isInterested.categories.convenience.title',
          options: [
            { label: 'surveyConfig.general.yes', value: 'yes', disqualify: false },
            { label: 'surveyConfig.general.maybe', value: 'maybe', disqualify: false },
            { label: 'surveyConfig.general.notRightNow', value: 'not-right-now', disqualify: false },
            { label: 'surveyConfig.general.no', value: 'no', disqualify: true },
          ]
        },
        niche: {
          title: 'surveyConfig.productSteps.isInterested.categories.niche.title',
          options: [
            { label: 'surveyConfig.general.yes', value: 'yes', disqualify: false },
            { label: 'surveyConfig.general.maybe', value: 'maybe', disqualify: false },
            { label: 'surveyConfig.general.notRightNow', value: 'not-right-now', disqualify: false },
            { label: 'surveyConfig.general.no', value: 'no', disqualify: true },
          ]
        },
      }
    },
    {
      step: 'feature',
      categories: {
        general: {
          title: 'surveyConfig.productSteps.feature.categories.general.title',
          titleShort: 'Attributes for: [item.name]',
          options: '[item.features]'
        },
        food: {
          title: 'surveyConfig.productSteps.feature.categories.food.title',
          titleShort: 'Attributes for: [item.name]',
          options: '[item.features]'

        },
        drink: {
          title: 'surveyConfig.productSteps.feature.categories.drink.title',
          titleShort: 'Attributes for: [item.name]',
          options: '[item.features]'

        },
        premium: {
          title: 'surveyConfig.productSteps.feature.categories.premium.title',
          titleShort: 'Attributes for: [item.name]',
          options: '[item.features]'
        },
        durable: {
          title: 'surveyConfig.productSteps.feature.categories.durable.title',
          titleShort: 'Attributes for: [item.name]',
          options: '[item.features]'
        },
        convenience: {
          title: 'surveyConfig.productSteps.feature.categories.convenience.title',
          titleShort: 'Attributes for: [item.name]',
          options: '[item.features]'
        },
        niche: {
          title: 'surveyConfig.productSteps.feature.categories.niche.title',
          titleShort: 'Attributes for: [item.name]',
          options: '[item.features]'
        },
      }
    },
    {
      step: 'uniqueFeature',
      categories: {
        general: {
          title: 'surveyConfig.productSteps.uniqueFeature.categories.general.title',
          titleShort: 'Secondary attributes',
          options: '[item.uniqueFeatures]'
        },
        food: {
          title: 'surveyConfig.productSteps.uniqueFeature.categories.food.title',
          titleShort: 'Secondary attributes',
          options: '[item.uniqueFeatures]'
        },
        drink: {
          title: 'surveyConfig.productSteps.uniqueFeature.categories.drink.title',
          titleShort: 'Secondary attributes',
          options: '[item.uniqueFeatures]'
        },
        premium: {
          title: 'surveyConfig.productSteps.uniqueFeature.categories.premium.title',
          titleShort: 'Secondary attributes',
          options: '[item.uniqueFeatures]'
        },
        durable: {
          title: 'surveyConfig.productSteps.uniqueFeature.categories.durable.title',
          titleShort: 'Secondary attributes',
          options: '[item.uniqueFeatures]'
        },
        convenience: {
          title: 'surveyConfig.productSteps.uniqueFeature.categories.convenience.title',
          titleShort: 'Secondary attributes',
          options: '[item.uniqueFeatures]'
        },
        niche: {
          title: 'surveyConfig.productSteps.uniqueFeature.categories.niche.title',
          titleShort: 'Secondary attributes',
          options: '[item.uniqueFeatures]'
        },
      }
    },
    {
      step: 'disadvantage',
      categories: {
        general: {
          title: 'surveyConfig.productSteps.disadvantage.categories.general.title',
          titleShort: 'Disadvantages for: [item.name]',
          options: '[item.disadvantages]'
        },
        food: {
          title: 'surveyConfig.productSteps.disadvantage.categories.food.title',
          titleShort: 'Disadvantages for: [item.name]',
          options: '[item.disadvantages]'
        },
        drink: {
          title: 'surveyConfig.productSteps.disadvantage.categories.drink.title',
          titleShort: 'Disadvantages for: [item.name]',
          options: '[item.disadvantages]'
        },
        premium: {
          title: 'surveyConfig.productSteps.disadvantage.categories.premium.title',
          titleShort: 'Disadvantages for: [item.name]',
          options: '[item.disadvantages]'
        },
        durable: {
          title: 'surveyConfig.productSteps.disadvantage.categories.durable.title',
          titleShort: 'Disadvantages for: [item.name]',
          options: '[item.disadvantages]'
        },
        convenience: {
          title: 'surveyConfig.productSteps.disadvantage.categories.convenience.title',
          titleShort: 'Disadvantages for: [item.name]',
          options: '[item.disadvantages]'
        },
        niche: {
          title: 'surveyConfig.productSteps.disadvantage.categories.niche.title',
          titleShort: 'Disadvantages for: [item.name]',
          options: '[item.disadvantages]'
        },
      }
    },
    {
      step: 'environmentSustainability',
      categories: {
        general: {
          title: 'surveyConfig.productSteps.environmentSustainability.categories.general.title',
          titleShort: 'Environmental sustainability',
          options: [
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.food.options.extremely_important',
              value: 'extremely_important'
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.food.options.somewhat_important',
              value: 'somewhat_important',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.food.options.indifferent',
              value: 'indifferent',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.food.options.somewhat_unimportant',
              value: 'somewhat_unimportant',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.food.options.extremely_unimportant',
              value: 'extremely_unimportant',
            },
          ]
        },
        food: {
          title: 'surveyConfig.productSteps.environmentSustainability.categories.food.title',
          titleShort: 'Environmental sustainability',
          options: [
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.food.options.extremely_important',
              value: 'extremely_important'
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.food.options.somewhat_important',
              value: 'somewhat_important',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.food.options.indifferent',
              value: 'indifferent',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.food.options.somewhat_unimportant',
              value: 'somewhat_unimportant',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.food.options.extremely_unimportant',
              value: 'extremely_unimportant',
            },
          ]
        },
        drink: {
          title: 'surveyConfig.productSteps.environmentSustainability.categories.drink.title',
          titleShort: 'Environmental sustainability',
          options: [
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.drink.options.extremely_important',
              value: 'extremely_important'
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.drink.options.somewhat_important',
              value: 'somewhat_important',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.drink.options.indifferent',
              value: 'indifferent',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.drink.options.somewhat_unimportant',
              value: 'somewhat_unimportant',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.drink.options.extremely_unimportant',
              value: 'extremely_unimportant',
            },
          ]
        },
        premium: {
          title: 'surveyConfig.productSteps.environmentSustainability.categories.premium.title',
          titleShort: 'Environmental sustainability',
          options: [
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.premium.options.extremely_important',
              value: 'extremely_important'
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.premium.options.somewhat_important',
              value: 'somewhat_important',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.premium.options.indifferent',
              value: 'indifferent',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.premium.options.somewhat_unimportant',
              value: 'somewhat_unimportant',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.premium.options.extremely_unimportant',
              value: 'extremely_unimportant',
            },
          ]
        },
        durable: {
          title: 'surveyConfig.productSteps.environmentSustainability.categories.durable.title',
          titleShort: 'Environmental sustainability',
          options: [
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.durable.options.extremely_important',
              value: 'extremely_important'
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.durable.options.somewhat_important',
              value: 'somewhat_important',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.durable.options.indifferent',
              value: 'indifferent',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.durable.options.somewhat_unimportant',
              value: 'somewhat_unimportant',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.durable.options.extremely_unimportant',
              value: 'extremely_unimportant',
            },
          ]
        },
        convenience: {
          title: 'surveyConfig.productSteps.environmentSustainability.categories.convenience.title',
          titleShort: 'Environmental sustainability',
          options: [
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.convenience.options.extremely_important',
              value: 'extremely_important'
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.convenience.options.somewhat_important',
              value: 'somewhat_important',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.convenience.options.indifferent',
              value: 'indifferent',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.convenience.options.somewhat_unimportant',
              value: 'somewhat_unimportant',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.convenience.options.extremely_unimportant',
              value: 'extremely_unimportant',
            },
          ]
        },
        niche: {
          title: 'surveyConfig.productSteps.environmentSustainability.categories.niche.title',
          titleShort: 'Environmental sustainability',
          options: [
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.niche.options.extremely_important',
              value: 'extremely_important'
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.niche.options.somewhat_important',
              value: 'somewhat_important',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.niche.options.indifferent',
              value: 'indifferent',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.niche.options.somewhat_unimportant',
              value: 'somewhat_unimportant',
            },
            {
              label: 'surveyConfig.productSteps.environmentSustainability.categories.niche.options.extremely_unimportant',
              value: 'extremely_unimportant',
            },
          ]
        },
      }
    },
    {
      step: 'competitor',
      categories: {
        general: {
          title: 'surveyConfig.productSteps.competitor.categories.general.title',
          titleShort: 'You & Your Competitors',
          options: '[item.competitors]',
        },
        food: {
          title: 'surveyConfig.productSteps.competitor.categories.food.title',
          titleShort: 'You & Your Competitors',
          options: '[item.competitors]',
        },
        drink: {
          title: 'surveyConfig.productSteps.competitor.categories.drink.title',
          titleShort: 'You & Your Competitors',
          options: '[item.competitors]',
        },
        premium: {
          title: 'surveyConfig.productSteps.competitor.categories.premium.title',
          titleShort: 'You & Your Competitors',
          options: '[item.competitors]',
        },
        durable: {
          title: 'surveyConfig.productSteps.competitor.categories.durable.title',
          titleShort: 'You & Your Competitors',
          options: '[item.competitors]'
        },
        convenience: {
          title: 'surveyConfig.productSteps.competitor.categories.convenience.title',
          titleShort: 'You & Your Competitors',
          options: '[item.competitors]'
        },
        niche: {
          title: 'surveyConfig.productSteps.competitor.categories.niche.title',
          titleShort: 'You & Your Competitors',
          options: '[item.competitors]'
        },
      }
    },
    {
      step: 'usage',
      categories: {
        general: {
          title: 'surveyConfig.productSteps.usage.categories.general.title',
          titleShort: 'When to use: [item.name]',
          options: '[item.usage]',
        },
        food: {
          title: 'surveyConfig.productSteps.usage.categories.food.title',
          titleShort: 'When to eat: [item.name]',
          options: '[item.usage]',
        },
        drink: {
          title: 'surveyConfig.productSteps.usage.categories.drink.title',
          titleShort: 'When to drink: [item.name]',
          options: '[item.usage]',
        },
        premium: {
          title: 'surveyConfig.productSteps.usage.categories.premium.title',
          titleShort: 'When to use: [item.name]',
          options: '[item.usage]',
        },
        durable: {
          title: 'surveyConfig.productSteps.usage.categories.durable.title',
          titleShort: 'When to use: [item.name]',
          options: '[item.usage]',
        },
        convenience: {
          title: 'surveyConfig.productSteps.usage.categories.convenience.title',
          titleShort: 'When to use: [item.name]',
          options: '[item.usage]',
        },
        niche: {
          title: 'surveyConfig.productSteps.usage.categories.niche.title',
          titleShort: 'When to use: [item.name]',
          options: '[item.usage]',
        },
      }
    },
    {
      step: 'retailLocation',
      categories: {
        general: {
          title: 'surveyConfig.productSteps.retailLocation.categories.general.title',
          titleShort: 'Where to buy: [item.name]',
          options: '[item.retailLocations]',
        },
        food: {
          title: 'surveyConfig.productSteps.retailLocation.categories.food.title',
          titleShort: 'Where to buy: [item.name]',
          options: '[item.retailLocations]',
        },
        drink: {
          title: 'surveyConfig.productSteps.retailLocation.categories.drink.title',
          titleShort: 'Where to buy: [item.name]',
          options: '[item.retailLocations]',
        },
        premium: {
          title: 'surveyConfig.productSteps.retailLocation.categories.premium.title',
          titleShort: 'Where to buy: [item.name]',
          options: '[item.retailLocations]',
        },
        durable: {
          title: 'surveyConfig.productSteps.retailLocation.categories.durable.title',
          titleShort: 'Where to buy: [item.name]',
          options: '[item.retailLocations]',
        },
        convenience: {
          title: 'surveyConfig.productSteps.retailLocation.categories.convenience.title',
          titleShort: 'Where to buy: [item.name]',
          options: '[item.retailLocations]',
        },
        niche: {
          title: 'surveyConfig.productSteps.retailLocation.categories.niche.title',
          titleShort: 'Where to buy: [item.name]',
          options: '[item.retailLocations]',
        },
      }
    },
    {
      step: 'socialMedia',
      categories: {
        general: {
          title: 'surveyConfig.productSteps.socialMedia.categories.general.title',
          subTitle: 'surveyConfig.productSteps.socialMedia.categories.general.subTitle',
          titleShort: 'Social Media Preference',
          options: SOCIAL_NETWORKS_OPTIONS,
        },
        food: {
          title: 'surveyConfig.productSteps.socialMedia.categories.food.title',
          subTitle: 'surveyConfig.productSteps.socialMedia.categories.food.subTitle',
          titleShort: 'Social Media Preference',
          options: SOCIAL_NETWORKS_OPTIONS,
        },
        drink: {
          title: 'surveyConfig.productSteps.socialMedia.categories.drink.title',
          subTitle: 'surveyConfig.productSteps.socialMedia.categories.drink.subTitle',
          titleShort: 'Social Media Preference',
          options: SOCIAL_NETWORKS_OPTIONS,
        },
        premium: {
          title: 'surveyConfig.productSteps.socialMedia.categories.premium.title',
          subTitle: 'surveyConfig.productSteps.socialMedia.categories.premium.subTitle',
          titleShort: 'Social Media Preference',
          options: SOCIAL_NETWORKS_OPTIONS,
        },
        durable: {
          title: 'surveyConfig.productSteps.socialMedia.categories.durable.title',
          subTitle: 'surveyConfig.productSteps.socialMedia.categories.durable.subTitle',
          titleShort: 'Social Media Preference',
          options: SOCIAL_NETWORKS_OPTIONS,
        },
        convenience: {
          title: 'surveyConfig.productSteps.socialMedia.categories.convenience.title',
          subTitle: 'surveyConfig.productSteps.socialMedia.categories.convenience.subTitle',
          titleShort: 'Social Media Preference',
          options: SOCIAL_NETWORKS_OPTIONS,
        },
        niche: {
          title: 'surveyConfig.productSteps.socialMedia.categories.niche.title',
          subTitle: 'surveyConfig.productSteps.socialMedia.categories.niche.subTitle',
          titleShort: 'Social Media Preference',
          options: SOCIAL_NETWORKS_OPTIONS,
        },
      }
    },
    {
      step: 'price.low',
      type: 'danger',
      intro: 'surveyConfig.productSteps.price.low.intro',
      categories: {
        general: {
          body: [
            'surveyConfig.productSteps.price.low.categories.general.content1',
            'surveyConfig.productSteps.price.low.categories.general.content2',
            'surveyConfig.productSteps.price.low.categories.general.content3',
          ]
        },
        food: {
          body: [
            'surveyConfig.productSteps.price.low.categories.food.content1',
            'surveyConfig.productSteps.price.low.categories.food.content2',
            'surveyConfig.productSteps.price.low.categories.food.content3',
          ]
        },
        drink: {
          body: [
            'surveyConfig.productSteps.price.low.categories.drink.content1',
            'surveyConfig.productSteps.price.low.categories.drink.content2',
            'surveyConfig.productSteps.price.low.categories.drink.content3',
          ]
        },
        premium: {
          body: [
            'surveyConfig.productSteps.price.low.categories.premium.content1',
            'surveyConfig.productSteps.price.low.categories.premium.content2',
            'surveyConfig.productSteps.price.low.categories.premium.content3',
          ]
        },
        durable: {
          body: [
            'surveyConfig.productSteps.price.low.categories.durable.content1',
            'surveyConfig.productSteps.price.low.categories.durable.content2',
            'surveyConfig.productSteps.price.low.categories.durable.content3',
          ]
        },
        convenience: {
          body: [
            'surveyConfig.productSteps.price.low.categories.convenience.content1',
            'surveyConfig.productSteps.price.low.categories.convenience.content2',
            'surveyConfig.productSteps.price.low.categories.convenience.content3',
          ]
        },
        niche: {
          body: [
            'surveyConfig.productSteps.price.low.categories.niche.content1',
            'surveyConfig.productSteps.price.low.categories.niche.content2',
            'surveyConfig.productSteps.price.low.categories.niche.content3',
          ]
        },
      }
    },
    {
      step: 'price.high',
      type: 'danger',
      body: [
        'surveyConfig.productSteps.price.high.content1',
        'surveyConfig.productSteps.price.high.content2',
      ]
    },
    {
      step: 'price.midHigh',
      type: 'success',
      body: [
        'surveyConfig.productSteps.price.midHigh.content1',
        'surveyConfig.productSteps.price.midHigh.content2'
      ]
    },
    {
      step: 'price.midLow',
      type: 'success',
      body: [
        'surveyConfig.productSteps.price.midLow.content1',
        'surveyConfig.productSteps.price.midLow.content2'
      ]
    },
  ]
};

const serviceSurveyConfig = {
  type: 'survey',
  steps: [
    {
      step: 'intro',
      body: [
        'surveyConfig.serviceSteps.intro.body.content1',
        'surveyConfig.serviceSteps.intro.body.content2',
        'surveyConfig.serviceSteps.intro.body.content3'
      ]
    },
    {
      step: 'isInterested',
      categories: {
        general: {
          title: 'surveyConfig.serviceSteps.isInterested.categories.general.title',
          options: [
            { label: 'surveyConfig.general.yes', value: 'yes', disqualify: false },
            { label: 'surveyConfig.general.maybe', value: 'maybe', disqualify: false },
            { label: 'surveyConfig.general.notRightNow', value: 'not-right-now', disqualify: false },
            { label: 'surveyConfig.general.no', value: 'no', disqualify: true },
          ]
        },
        premium: {
          title: 'surveyConfig.serviceSteps.isInterested.categories.premium.title',
          options: [
            { label: 'surveyConfig.general.yes', value: 'yes', disqualify: false },
            { label: 'surveyConfig.general.maybe', value: 'maybe', disqualify: false },
            { label: 'surveyConfig.general.notRightNow', value: 'not-right-now', disqualify: false },
            { label: 'surveyConfig.general.no', value: 'no', disqualify: true },
          ]
        },
        frequent: {
          title: 'surveyConfig.serviceSteps.isInterested.categories.frequent.title',
          options: [
            { label: 'surveyConfig.general.yes', value: 'yes', disqualify: false },
            { label: 'surveyConfig.general.maybe', value: 'maybe', disqualify: false },
            { label: 'surveyConfig.general.notRightNow', value: 'not-right-now', disqualify: false },
            { label: 'surveyConfig.general.no', value: 'no', disqualify: true },
          ]
        },
        niche: {
          title: 'surveyConfig.serviceSteps.isInterested.categories.niche.title',
          options: [
            { label: 'surveyConfig.general.yes', value: 'yes', disqualify: false },
            { label: 'surveyConfig.general.maybe', value: 'maybe', disqualify: false },
            { label: 'surveyConfig.general.notRightNow', value: 'not-right-now', disqualify: false },
            { label: 'surveyConfig.general.no', value: 'no', disqualify: true },
          ]
        },
      }
    },
    {
      step: 'feature',
      categories: {
        general: {
          title: 'surveyConfig.serviceSteps.feature.categories.general.title',
          titleShort: 'Attributes for: [item.name]',
          options: '[item.features]'
        },
        premium: {
          title: 'surveyConfig.serviceSteps.feature.categories.premium.title',
          titleShort: 'Attributes for: [item.name]',
          options: '[item.features]'
        },
        frequent: {
          title: 'surveyConfig.serviceSteps.feature.categories.frequent.title',
          titleShort: 'Attributes for: [item.name]',
          options: '[item.features]'
        },
        niche: {
          title: 'surveyConfig.serviceSteps.feature.categories.niche.title',
          titleShort: 'Attributes for: [item.name]',
          options: '[item.features]'
        },
      }
    },
    {
      step: 'uniqueFeature',
      categories: {
        general: {
          title: 'surveyConfig.serviceSteps.uniqueFeature.categories.general.title',
          titleShort: 'Attributes for: [item.name]',
          options: '[item.uniqueFeatures]'
        },
        premium: {
          title: 'surveyConfig.serviceSteps.uniqueFeature.categories.premium.title',
          titleShort: 'Secondary attributes',
          options: '[item.uniqueFeatures]'
        },
        frequent: {
          title: 'surveyConfig.serviceSteps.uniqueFeature.categories.frequent.title',
          titleShort: 'Secondary attributes',
          options: '[item.uniqueFeatures]'
        },
        niche: {
          title: 'surveyConfig.serviceSteps.uniqueFeature.categories.niche.title',
          titleShort: 'Secondary attributes',
          options: '[item.uniqueFeatures]'
        },
      }
    },
    {
      step: 'disadvantage',
      categories: {
        general: {
          title: 'surveyConfig.serviceSteps.disadvantage.categories.general.title',
          titleShort: 'Disadvantages for: [item.name]',
          options: '[item.disadvantages]'
        },
        premium: {
          title: 'surveyConfig.serviceSteps.disadvantage.categories.premium.title',
          titleShort: 'Disadvantages for: [item.name]',
          options: '[item.disadvantages]'
        },
        frequent: {
          title: 'surveyConfig.serviceSteps.disadvantage.categories.frequent.title',
          titleShort: 'Disadvantages for: [item.name]',
          options: '[item.disadvantages]'
        },
        niche: {
          title: 'surveyConfig.serviceSteps.disadvantage.categories.niche.title',
          titleShort: 'Disadvantages for: [item.name]',
          options: '[item.disadvantages]'
        },
      }
    },
    {
      step: 'competitor',
      categories: {
        general: {
          title: 'surveyConfig.serviceSteps.competitor.categories.general.title',
          titleShort: 'You & Your Competitors',
          options: '[item.competitors]'
        },
        premium: {
          title: 'surveyConfig.serviceSteps.competitor.categories.premium.title',
          titleShort: 'You & Your Competitors',
          options: '[item.competitors]'
        },
        frequent: {
          title: 'surveyConfig.serviceSteps.competitor.categories.frequent.title',
          titleShort: 'You & Your Competitors',
          options: '[item.competitors]'
        },
        niche: {
          title: 'surveyConfig.serviceSteps.competitor.categories.niche.title',
          titleShort: 'You & Your Competitors',
          options: '[item.competitors]'
        },
      }
    },
    {
      step: 'usage',
      categories: {
        general: {
          title: 'surveyConfig.serviceSteps.usage.categories.general.title',
          titleShort: 'When to use [item.name]',
          options: '[item.usage]',
        },
        premium: {
          title: 'surveyConfig.serviceSteps.usage.categories.premium.title',
          titleShort: 'Reasons for using [item.name]',
          options: '[item.usage]',
        },
        frequent: {
          title: 'surveyConfig.serviceSteps.usage.categories.frequent.title',
          titleShort: 'Reasons for using [item.name]',
          options: '[item.usage]',
        },
        niche: {
          title: 'surveyConfig.serviceSteps.usage.categories.niche.title',
          titleShort: 'Reasons for using [item.name]',
          options: '[item.usage]',
        },
      }
    },
    {
      step: 'retailLocation',
      categories: {
        general: {
          title: 'surveyConfig.serviceSteps.retailLocation.categories.general.title',
          titleShort: 'Where to buy [item.name]',
          options: '[item.retailLocations]',
        },
        premium: {
          title: 'surveyConfig.serviceSteps.retailLocation.categories.premium.title',
          titleShort: 'Where to buy [item.name]',
          options: '[item.retailLocations]',
        },
        frequent: {
          title: 'surveyConfig.serviceSteps.retailLocation.categories.frequent.title',
          titleShort: 'Where to buy [item.name]',
          options: '[item.retailLocations]',
        },
        niche: {
          title: 'surveyConfig.serviceSteps.retailLocation.categories.niche.title',
          titleShort: 'Where to buy [item.name]',
          options: '[item.retailLocations]',
        },
      }
    },
    {
      step: 'retailPeriod',
      categories: {
        general: {
          title: 'surveyConfig.serviceSteps.retailPeriod.categories.general.title',
          titleShort: 'When to use [item.name]',
          options: '[item.retailPeriods]',
        },
        premium: {
          title: 'surveyConfig.serviceSteps.retailPeriod.categories.premium.title',
          titleShort: 'When to use [item.name]',
          options: '[item.retailPeriods]',
        },
        frequent: {
          title: 'surveyConfig.serviceSteps.retailPeriod.categories.frequent.title',
          titleShort: 'When to use [item.name]',
          options: '[item.retailPeriods]',
        },
        niche: {
          title: 'surveyConfig.serviceSteps.retailPeriod.categories.niche.title',
          titleShort: 'When to use [item.name]',
          options: '[item.retailPeriods]',
        },
      }
    },
    {
      step: 'socialMedia',
      categories: {
        general: {
          title: 'surveyConfig.serviceSteps.socialMedia.categories.general.title',
          subTitle: 'surveyConfig.serviceSteps.socialMedia.categories.general.subTitle',
          titleShort: 'Social Media Preference',
          options: SOCIAL_NETWORKS_OPTIONS,
        },
        premium: {
          title: 'surveyConfig.serviceSteps.socialMedia.categories.premium.title',
          subTitle: 'surveyConfig.serviceSteps.socialMedia.categories.premium.subTitle',
          titleShort: 'Social Media Preference',
          options: SOCIAL_NETWORKS_OPTIONS,
        },
        frequent: {
          title: 'surveyConfig.serviceSteps.socialMedia.categories.frequent.title',
          subTitle: 'surveyConfig.serviceSteps.socialMedia.categories.frequent.subTitle',
          titleShort: 'Social Media Preference',
          options: SOCIAL_NETWORKS_OPTIONS,
        },
        niche: {
          title: 'surveyConfig.serviceSteps.socialMedia.categories.niche.title',
          subTitle: 'surveyConfig.serviceSteps.socialMedia.categories.niche.subTitle',
          titleShort: 'Social Media Preference',
          options: SOCIAL_NETWORKS_OPTIONS,
        },
      }
    },
    {
      step: 'price.low',
      type: 'danger',
      intro: 'surveyConfig.serviceSteps.price.low.intro',
      categories: {
        general: {
          body: [
            'surveyConfig.serviceSteps.price.low.categories.general.content1',
            'surveyConfig.serviceSteps.price.low.categories.general.content2',
            'surveyConfig.serviceSteps.price.low.categories.general.content3',
          ]
        },
        premium: {
          body: [
            'surveyConfig.serviceSteps.price.low.categories.premium.content1',
            'surveyConfig.serviceSteps.price.low.categories.premium.content2',
            'surveyConfig.serviceSteps.price.low.categories.premium.content3',
          ]
        },
        frequent: {
          body: [
            'surveyConfig.serviceSteps.price.low.categories.frequent.content1',
            'surveyConfig.serviceSteps.price.low.categories.frequent.content2',
            'surveyConfig.serviceSteps.price.low.categories.frequent.content3',
          ]
        },
        niche: {
          body: [
            'surveyConfig.serviceSteps.price.low.categories.niche.content1',
            'surveyConfig.serviceSteps.price.low.categories.niche.content2',
            'surveyConfig.serviceSteps.price.low.categories.niche.content3',
          ]
        },
      },
    },
    {
      step: 'price.high',
      type: 'danger',
      body: [
        'surveyConfig.serviceSteps.price.high.content1',
        'surveyConfig.serviceSteps.price.high.content2'
      ]
    },
    {
      step: 'price.midHigh',
      type: 'success',
      body: [
        'surveyConfig.serviceSteps.price.midHigh.content1',
        'surveyConfig.serviceSteps.price.midHigh.content2'
      ]
    },
    {
      step: 'price.midLow',
      type: 'success',
      body: [
        'surveyConfig.serviceSteps.price.midLow.content1',
        'surveyConfig.serviceSteps.price.midLow.content2'
      ]
    },
  ]
};

export const paymentMethodStepConfig = {
  product: {
    step: 'paymentMethod',
    categories: {
      general: {
        title: 'surveyConfig.productSteps.paymentMethod.categories.general.title',
        options: [
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.general.options.per_unit_sold',
            value: 'per_unit_sold'
          },
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.general.options.per_month',
            value: 'per_month'
          },
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.general.options.per_year',
            value: 'per_year'
          },
        ],
      },
      food: {
        title: 'surveyConfig.productSteps.paymentMethod.categories.food.title',
        options: [
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.food.options.per_unit_sold',
            value: 'per_unit_sold'
          },
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.food.options.per_month',
            value: 'per_month'
          },
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.food.options.per_year',
            value: 'per_year'
          },
        ],
      },
      drink: {
        title: 'surveyConfig.productSteps.paymentMethod.categories.drink.title',
        options: [
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.drink.options.per_unit_sold',
            value: 'per_unit_sold'
          },
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.drink.options.per_month',
            value: 'per_month'
          },
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.drink.options.per_year',
            value: 'per_year'
          },
        ],
      },
      premium: {
        title: 'surveyConfig.productSteps.paymentMethod.categories.premium.title',
        options: [
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.premium.options.per_unit_sold',
            value: 'per_unit_sold'
          },
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.premium.options.per_month',
            value: 'per_month'
          },
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.premium.options.per_year',
            value: 'per_year'
          },
        ],
      },
      durable: {
        title: 'surveyConfig.productSteps.paymentMethod.categories.durable.title',
        options: [
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.durable.options.per_unit_sold',
            value: 'per_unit_sold'
          },
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.durable.options.per_month',
            value: 'per_month'
          },
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.durable.options.per_year',
            value: 'per_year'
          },
        ],
      },
      convenience: {
        title: 'surveyConfig.productSteps.paymentMethod.categories.convenience.title',
        options: [
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.convenience.options.per_unit_sold',
            value: 'per_unit_sold'
          },
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.convenience.options.per_month',
            value: 'per_month'
          },
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.convenience.options.per_year',
            value: 'per_year'
          },
        ],
      },
      niche: {
        title: 'surveyConfig.productSteps.paymentMethod.categories.niche.title',
        options: [
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.niche.options.per_unit_sold',
            value: 'per_unit_sold'
          },
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.niche.options.per_month',
            value: 'per_month'
          },
          {
            label: 'surveyConfig.productSteps.paymentMethod.categories.niche.options.per_year',
            value: 'per_year'
          },
        ],
      },
    }
  },
  service: {
    step: 'paymentMethod',
    categories: {
      general: {
        title: 'surveyConfig.serviceSteps.paymentMethod.categories.general.title',
        options: [
          { label: 'surveyConfig.serviceSteps.paymentMethod.categories.general.options.fixed', value: 'fixed' },
          { label: 'surveyConfig.serviceSteps.paymentMethod.categories.general.options.per_hour', value: 'per_hour' },
          { label: 'surveyConfig.serviceSteps.paymentMethod.categories.general.options.per_week', value: 'per_week' },
          { label: 'surveyConfig.serviceSteps.paymentMethod.categories.general.options.per_month', value: 'per_month' },
          { label: 'surveyConfig.serviceSteps.paymentMethod.categories.general.options.per_year', value: 'per_year' },
        ]
      },
      premium: {
        frequent: 'surveyConfig.serviceSteps.paymentMethod.categories.premium.title',
        options: [
          { label: 'surveyConfig.serviceSteps.paymentMethod.categories.premium.options.fixed', value: 'fixed' },
          { label: 'surveyConfig.serviceSteps.paymentMethod.categories.premium.options.per_hour', value: 'per_hour' },
          { label: 'surveyConfig.serviceSteps.paymentMethod.categories.premium.options.per_week', value: 'per_week' },
          { label: 'surveyConfig.serviceSteps.paymentMethod.categories.premium.options.per_month', value: 'per_month' },
          { label: 'surveyConfig.serviceSteps.paymentMethod.categories.premium.options.per_year', value: 'per_year' },
        ]
      },
      frequent: {
        title: 'surveyConfig.serviceSteps.paymentMethod.categories.frequent.title',
        options: [
          { label: 'surveyConfig.serviceSteps.paymentMethod.categories.premium.options.fixed', value: 'fixed' },
          { label: 'surveyConfig.serviceSteps.paymentMethod.categories.premium.options.per_hour', value: 'per_hour' },
          { label: 'surveyConfig.serviceSteps.paymentMethod.categories.premium.options.per_week', value: 'per_week' },
          { label: 'surveyConfig.serviceSteps.paymentMethod.categories.premium.options.per_month', value: 'per_month' },
          { label: 'surveyConfig.serviceSteps.paymentMethod.categories.premium.options.per_year', value: 'per_year' },
        ]
      },
      niche: {
        title: 'surveyConfig.serviceSteps.paymentMethod.categories.niche.title',
        options: [
          { label: 'surveyConfig.serviceSteps.paymentMethod.categories.premium.options.fixed', value: 'fixed' },
          { label: 'surveyConfig.serviceSteps.paymentMethod.categories.premium.options.per_hour', value: 'per_hour' },
          { label: 'surveyConfig.serviceSteps.paymentMethod.categories.premium.options.per_week', value: 'per_week' },
          { label: 'surveyConfig.serviceSteps.paymentMethod.categories.premium.options.per_month', value: 'per_month' },
          { label: 'surveyConfig.serviceSteps.paymentMethod.categories.premium.options.per_year', value: 'per_year' },
        ]
      }
    },
  }
};
export const usageFrequencyStepConfig = {
  product: {
    step: 'usageFrequency',
    categories: {
      general: {
        title: 'surveyConfig.productSteps.usageFrequency.general.title',
        titleShort: 'Usage for [item.name]',
        options: [
          { label: 'surveyConfig.productSteps.usageFrequency.general.options.daily', value: 'daily' },
          {
            label: 'surveyConfig.productSteps.usageFrequency.general.options.few_times_every_week',
            value: 'few_times_every_week',
          },
          {
            label: 'surveyConfig.productSteps.usageFrequency.general.options.few_times_every_month',
            value: 'few_times_every_month',
          },
          {
            label: 'surveyConfig.productSteps.usageFrequency.general.options.few_times_every_year',
            value: 'few_times_every_year',
          },
          {
            label: 'surveyConfig.productSteps.usageFrequency.general.options.less_than_once_per_year',
            value: 'less_than_once_per_year',
          },
        ]
      },
      food: {
        title: 'surveyConfig.productSteps.usageFrequency.food.title',
        titleShort: 'Usage for [item.name]',
        options: [
          { label: 'surveyConfig.productSteps.usageFrequency.food.options.daily', value: 'daily' },
          {
            label: 'surveyConfig.productSteps.usageFrequency.food.options.few_times_every_week',
            value: 'few_times_every_week',
          },
          {
            label: 'surveyConfig.productSteps.usageFrequency.food.options.few_times_every_month',
            value: 'few_times_every_month',
          },
          {
            label: 'surveyConfig.productSteps.usageFrequency.food.options.less_than_once_per_year',
            value: 'less_than_once_per_year',
          },
        ]
      },
      drink: {
        title: 'surveyConfig.productSteps.usageFrequency.drink.title',
        titleShort: 'Usage for [item.name]',
        options: [
          { label: 'surveyConfig.productSteps.usageFrequency.drink.options.daily', value: 'daily' },
          {
            label: 'surveyConfig.productSteps.usageFrequency.drink.options.few_times_every_week',
            value: 'few_times_every_week',
          },
          {
            label: 'surveyConfig.productSteps.usageFrequency.drink.options.few_times_every_month',
            value: 'few_times_every_month',
          },
          {
            label: 'surveyConfig.productSteps.usageFrequency.drink.options.less_than_once_per_year',
            value: 'less_than_once_per_year',
          },
        ]
      },
      premium: {
        title: 'surveyConfig.productSteps.usageFrequency.premium.title',
        titleShort: 'Usage for [item.name]',
        options: [
          { label: 'surveyConfig.productSteps.usageFrequency.premium.options.daily', value: 'daily' },
          {
            label: 'surveyConfig.productSteps.usageFrequency.premium.options.few_times_every_week',
            value: 'few_times_every_week',
          },
          {
            label: 'surveyConfig.productSteps.usageFrequency.premium.options.few_times_every_month',
            value: 'few_times_every_month',
          },
          {
            label: 'surveyConfig.productSteps.usageFrequency.premium.options.less_than_once_per_year',
            value: 'less_than_once_per_year',
          },
        ]
      },
      durable: {
        title: 'surveyConfig.productSteps.usageFrequency.durable.title',
        titleShort: 'Usage for [item.name]',
        options: [
          { label: 'surveyConfig.productSteps.usageFrequency.durable.options.daily', value: 'daily' },
          {
            label: 'surveyConfig.productSteps.usageFrequency.durable.options.few_times_every_week',
            value: 'few_times_every_week',
          },
          {
            label: 'surveyConfig.productSteps.usageFrequency.durable.options.few_times_every_month',
            value: 'few_times_every_month',
          },
          {
            label: 'surveyConfig.productSteps.usageFrequency.durable.options.less_than_once_per_year',
            value: 'less_than_once_per_year',
          },
        ]
      },
      convenience: {
        title: 'surveyConfig.productSteps.usageFrequency.convenience.title',
        titleShort: 'Usage for [item.name]',
        options: [
          { label: 'surveyConfig.productSteps.usageFrequency.convenience.options.daily', value: 'daily' },
          {
            label: 'surveyConfig.productSteps.usageFrequency.convenience.options.few_times_every_week',
            value: 'few_times_every_week',
          },
          {
            label: 'surveyConfig.productSteps.usageFrequency.convenience.options.few_times_every_month',
            value: 'few_times_every_month',
          },
          {
            label: 'surveyConfig.productSteps.usageFrequency.convenience.options.less_than_once_per_year',
            value: 'less_than_once_per_year',
          },
        ]
      },
      niche: {
        title: 'surveyConfig.productSteps.usageFrequency.niche.title',
        titleShort: 'Usage for [item.name]',
        options: [
          { label: 'surveyConfig.productSteps.usageFrequency.niche.options.daily', value: 'daily' },
          {
            label: 'surveyConfig.productSteps.usageFrequency.niche.options.few_times_every_week',
            value: 'few_times_every_week',
          },
          {
            label: 'surveyConfig.productSteps.usageFrequency.niche.options.few_times_every_month',
            value: 'few_times_every_month',
          },
          {
            label: 'surveyConfig.productSteps.usageFrequency.niche.options.less_than_once_per_year',
            value: 'less_than_once_per_year',
          },
        ]
      },
    }
  },
  service: {
    step: 'usageFrequency',
    categories: {
      premium: {
        title: 'surveyConfig.serviceSteps.usageFrequency.categories.premium.title',
        titleShort: 'Usage for [item.name]',
        options: [
          { label: 'surveyConfig.serviceSteps.usageFrequency.categories.premium.options.daily', value: 'daily' },
          {
            label: 'surveyConfig.serviceSteps.usageFrequency.categories.premium.options.few_times_every_week',
            value: 'few_times_every_week',
          },
          {
            label: 'surveyConfig.serviceSteps.usageFrequency.categories.premium.options.few_times_every_month',
            value: 'few_times_every_month',
          },
          {
            label: 'surveyConfig.serviceSteps.usageFrequency.categories.premium.options.less_than_once_per_year',
            value: 'less_than_once_per_year',
          },
        ]
      },
      general: {
        title: 'surveyConfig.serviceSteps.usageFrequency.categories.general.title',
        titleShort: 'Usage for [item.name]',
        options: [
          { label: 'surveyConfig.serviceSteps.usageFrequency.categories.general.options.daily', value: 'daily' },
          {
            label: 'surveyConfig.serviceSteps.usageFrequency.categories.general.options.few_times_every_week',
            value: 'few_times_every_week',
          },
          {
            label: 'surveyConfig.serviceSteps.usageFrequency.categories.general.options.few_times_every_month',
            value: 'few_times_every_month',
          },
          {
            label: 'surveyConfig.serviceSteps.usageFrequency.categories.general.options.less_than_once_per_year',
            value: 'less_than_once_per_year',
          },
        ]
      },
      frequent: {
        title: 'surveyConfig.serviceSteps.usageFrequency.categories.frequent.title',
        titleShort: 'Usage for [item.name]',
        options: [
          {
            label: 'surveyConfig.serviceSteps.usageFrequency.categories.frequent.options.weekly',
            value: 'weekly'
          },
          {
            label: 'surveyConfig.serviceSteps.usageFrequency.categories.frequent.options.several_times_a_month',
            value: 'several_times_a_month',
          },
          {
            label: 'surveyConfig.serviceSteps.usageFrequency.categories.frequent.options.at_least_once_a_month',
            value: 'at_least_once_a_month',
          },
          {
            label: 'surveyConfig.serviceSteps.usageFrequency.categories.frequent.options.less_than_4_times_a_year',
            value: 'less_than_4_times_a_year',
          },
        ]
      },
      niche: {
        title: 'surveyConfig.serviceSteps.usageFrequency.categories.niche.title',
        titleShort: 'Usage for [item.name]',
        options: [
          { label: 'surveyConfig.serviceSteps.usageFrequency.categories.niche.options.daily', value: 'daily' },
          {
            label: 'surveyConfig.serviceSteps.usageFrequency.categories.niche.options.few_times_every_week',
            value: 'few_times_every_week',
          },
          {
            label: 'surveyConfig.serviceSteps.usageFrequency.categories.niche.options.few_times_every_month',
            value: 'few_times_every_month',
          },
          {
            label: 'surveyConfig.serviceSteps.usageFrequency.categories.niche.options.less_than_once_per_year',
            value: 'less_than_once_per_year',
          },
        ]
      },
    }
  }
};
export const customProfilersStepConfig = {
  product: {
    steps: [
      {
        step: 'birthYear',
        categories: {
          general: {
            title: 'surveyConfig.customSteps.birthYear.categories.general.title',
            titleShort: 'Age',
            options: []
          }
        }
      },
      {
        step: 'gender',
        skipOptionsShuffle: true,
        categories: {
          general: {
            title: 'surveyConfig.customSteps.gender.categories.general.title',
            titleShort: 'Gender',
            options: [
              { label: 'surveyConfig.customSteps.gender.categories.general.options.male', value: '1' },
              { label: 'surveyConfig.customSteps.gender.categories.general.options.female', value: '2' },
              { label: 'surveyConfig.customSteps.gender.categories.general.options.non-binary', value: '3' },
              {
                label: 'surveyConfig.customSteps.gender.categories.general.options.prefer-not-to-say',
                value: '0'
              },
            ]
          }
        }
      },
      {
        step: 'region',
        categories: {
          general: {
            title: 'surveyConfig.customSteps.region.categories.general.title',
            titleShort: 'Region',
            options: []
          }
        }
      },
      {
        step: 'income',
        categories: {
          general: {
            title: 'surveyConfig.customSteps.income.categories.general.title',
            titleShort: 'Household income',
            options: []
          }
        }
      },
      // {
      //   step: 'professionalRole',
      //   categories: {
      //     general: {
      //       title: 'surveyConfig.customSteps.professionalRole.categories.general.title',
      //       titleShort: 'Professional Role',
      //       options: []
      //     }
      //   }
      // },
      // {
      //   step: 'industrySector',
      //   categories: {
      //     general: {
      //       title: 'surveyConfig.customSteps.industrySector.categories.general.title',
      //       titleShort: 'Industry Sector',
      //       options: []
      //     }
      //   }
      // },
    ],
  }
};

export const surveyConfig = {
  product: productSurveyConfig,
  service: serviceSurveyConfig
};

export type CategorySurveyConfig = typeof productSurveyConfig | typeof serviceSurveyConfig;
export type CategorySurveyStepConfigType = CategorySurveyConfig['steps'][number];
