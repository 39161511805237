import axios from 'axios';
import { SurveyType } from '../types/survey';


export const updateSurveyResponse = async (projectId: string, respondentId: string, survey: SurveyType): Promise<never> => {
  const url = `/survey/${projectId}/respondent/${respondentId}/response`;

  return await axios.put(url, survey);
};

export const updateCustomRespondentsPoolSurveyResponse = async (projectId: string, survey: SurveyType): Promise<SurveyType> => {
  const url = `/survey/${projectId}/response`;

  return await axios.put(url, survey);
};
