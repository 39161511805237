import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { DatePicker, Input, InputNumber, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import type { Project } from '../../../../../types/project';
import { RadioGroup } from '../../../../molecules/RadioGroup';
import { FormItem } from '../../../../molecules/FormItem';
import { CheckboxGroup } from '../../../../molecules/CheckboxGroup';
import type { FormItemProps } from '../../../../molecules/FormItem/FormItem.types';
import {
  StyledSurveyStepHighlightedPhrase,
  StyledSurveyStepParagraph,
  StyledSurveyStepPriceContentWrapper
} from './RenderSurveyStep.styles';
import { populateRegexValuesInString } from '../../../../../helpers/populateRegexValuesInString';
import { highlightSpecialPhraseInString } from '../../../../../helpers/highlightSpecialPhraseInString';
import { useOptionsTranslation } from '../../../../../hook/useOptionsTranslation';
import { CategorySurveyConfig } from '../../../../../constants/surveyConfig';
import { composeBodySurveyFormItem, composeRadioListSurveyFormItem } from './RenderSurveyStep.helper';
import type { SurveyFormPartialType } from '../../SurveyForm.types';
import { CINTRegion, ProfilingQuestion } from '../../../../../types/cint';


interface RenderSurveyStepProps extends FormItemProps {
  project?: Project;
  formValues: SurveyFormPartialType;
  // TODO: Create surveyConfig  interface
  projectSurveyConfig?: CategorySurveyConfig;
  surveyStepsConfig: any;
  currentStep?: string;
  createFormItemOptionClickHandler: (formItemName: string) => (index: number) => void;
  isUseGolfConfig?: boolean;
  regions?: CINTRegion[];
  incomeProfilingQuestion?: ProfilingQuestion;
}

// type HighlightType = 'danger' | 'success';

const highlightSpecialPhrase = (string: string, type?: 'danger' | 'success') => {
  return highlightSpecialPhraseInString(string, StyledSurveyStepHighlightedPhrase, type);
};

export const RenderSurveyStep: FC<RenderSurveyStepProps> = ({
  project,
  surveyStepsConfig,
  projectSurveyConfig,
  currentStep,
  createFormItemOptionClickHandler,
  isUseGolfConfig,
  formValues,
  regions,
  incomeProfilingQuestion,
  ...rest
}) => {
  const { t } = useTranslation();
  const { renderOptionsWithTranslate } = useOptionsTranslation();

  const populateProjectValueToString = useCallback((string: string) => {
    return populateRegexValuesInString(string, project);
  }, [project]);

  return useMemo(() => {
    // @ts-ignore
    const currentStepConfig = surveyStepsConfig?.find(stepConfig => stepConfig.step === currentStep);
    // @ts-ignore
    const currentStepSpellCheckedConfig = projectSurveyConfig?.steps?.find((stepConfig) => {
      return stepConfig.step === currentStep;
    });

    const currentStepName = currentStepConfig?.step;

    const currentItemCategory = project?.item?.category;

    type CategoryKey = typeof currentStepConfig.categories[keyof typeof currentStepConfig.categories];

    const currentItemCategoryConfig = currentItemCategory && currentStepConfig?.categories?.[currentItemCategory as CategoryKey];
    const currentCategoryStepSpellCheckedConfig = currentItemCategory && currentStepSpellCheckedConfig?.categories?.[currentItemCategory];

    switch (currentStep) {
      case 'intro':
        const bodyContent = currentStepSpellCheckedConfig?.body || currentStepConfig?.body;

        const body = bodyContent.map((bodyItem: string, index: number) => {
          let translatedBodyItem = bodyItem;

          if (bodyItem.startsWith('surveyConfig')) {
            translatedBodyItem = t(bodyItem);
          }

          const normalizedBodyItem = populateProjectValueToString(translatedBodyItem);

          return <StyledSurveyStepParagraph key={`${currentStepName}-paragraph-${index}`}>
              {normalizedBodyItem}
            </StyledSurveyStepParagraph>
        });

        return <div key={currentStepName}>{body}</div>;
      case 'isInterested':
      case 'usageFrequency':
      case 'environmentSustainability':
      case 'paymentMethod':
        if (!currentItemCategoryConfig) {
          return null;
        }

        const label = currentCategoryStepSpellCheckedConfig?.title || populateProjectValueToString(t(currentItemCategoryConfig?.title));

        return (
          <FormItem
            key={currentStepName}
            label={label}
            largeLabel
            labelDisplayBlock
            {...rest}
          >
              <RadioGroup
                options={renderOptionsWithTranslate(currentItemCategoryConfig?.options)}
                onClick={createFormItemOptionClickHandler(currentStepName)}
              />
            </FormItem>
        );
      case 'feature':
      case 'uniqueFeature':
      case 'disadvantage':
      case 'usage':
      case 'competitor':
      case 'retailLocation':
      case 'retailPeriod': {
        if (!currentItemCategoryConfig) {
          return null;
        }
        let options = currentItemCategoryConfig?.options;
        let parsedOptions: { label: string; value: string }[];

        if (!options || !Array.isArray(options)) {
          return null;
        } else {
          try {
            parsedOptions = renderOptionsWithTranslate(
              options,
              'value',
              `surveyConfig.${project?.type}Steps.${currentStepName}.categories.${currentItemCategory}.options`
            );
          } catch (error) {
            console.log(error);
            return null
          }
        }
        const label = currentCategoryStepSpellCheckedConfig?.title || populateProjectValueToString(t(currentItemCategoryConfig?.title));

        return (
          <FormItem
            key={currentStepName}
            label={label}
            largeLabel
            labelDisplayBlock
            {...rest}
          >
            <RadioGroup
              options={parsedOptions}
              onClick={createFormItemOptionClickHandler(currentStepName)}
            />
            </FormItem>
        );
      }
      case 'birthYear': {
        if (!currentItemCategoryConfig) {
          return null;
        }

        const label = currentCategoryStepSpellCheckedConfig?.title || populateProjectValueToString(t(currentItemCategoryConfig?.title));
        const formItemName = rest.name ?? currentStep;

        return (
          <FormItem
            key={currentStepName}
            label={label}
            largeLabel
            labelDisplayBlock
            {...rest}
            // @ts-ignore
            name={['metadata', 'respondent', formItemName]}
          >
            <DatePicker
              picker="year"
              allowClear={false}
              disabledDate={(currentDate) => {
                const currentYear = new Date().getFullYear();
                const pickerYearValue = currentDate.year();

                return pickerYearValue > currentYear;
              }}
            />
          </FormItem>
        );
      }
      case 'income': {
        if (!currentItemCategoryConfig) {
          return null;
        }

        const label = currentCategoryStepSpellCheckedConfig?.title || populateProjectValueToString(t(currentItemCategoryConfig?.title));
        const formItemName = rest.name ?? currentStep;

        return (
          <FormItem
            key={currentStepName}
            label={label}
            largeLabel
            labelDisplayBlock
            {...rest}
            // @ts-ignore
            name={['metadata', 'respondent', formItemName]}
          >
            <Select
              style={{ width: 300 }}
              placeholder="Select income"
              options={incomeProfilingQuestion?.variables}
              fieldNames={{
                label: 'name',
                value: 'id'
              }}
            />
          </FormItem>
        );
      }
      case 'region': {
        if (!currentItemCategoryConfig) {
          return null;
        }

        const label = currentCategoryStepSpellCheckedConfig?.title || populateProjectValueToString(t(currentItemCategoryConfig?.title));
        const formItemName = rest.name ?? currentStep;

        return (
          <Space align="end">
            <FormItem
              key={currentStepName}
              label={label}
              largeLabel
              labelDisplayBlock
              {...rest}
              // @ts-ignore
              name={['metadata', 'respondent', formItemName]}
            >
              <Select
                style={{ width: 300 }}
                placeholder="Region"
                options={regions}
                showSearch
                fieldNames={{
                  label: 'label',
                  value: 'label'
                }}
                filterOption={
                  (input, option) => {
                    if (typeof option?.label === 'string') {
                      return option?.label.toLowerCase().includes(input.toLowerCase());
                    }
                    return false;
                  }
                }
              />
            </FormItem>

            <FormItem
              key={currentStepName + 'Zip'}
              largeLabel
              labelDisplayBlock
              {...rest}
              // @ts-ignore
              name={['metadata', 'respondent', 'zip']}
            >
             <Input placeholder="ZIP" style={{ width: 300 }} />
            </FormItem>
          </Space>
        );
      }
      case 'gender': {
        if (!currentItemCategoryConfig) {
          return null;
        }
        let options = currentItemCategoryConfig?.options;
        let parsedOptions: { label: string; value: string }[];

        if (!options || !Array.isArray(options)) {
          return null;
        } else {
          try {
            parsedOptions = renderOptionsWithTranslate(options);
          } catch (error) {
            console.log(error);
            return null
          }
        }
        const label = currentCategoryStepSpellCheckedConfig?.title || populateProjectValueToString(t(currentItemCategoryConfig?.title));

        const formItemName = rest.name ?? currentStep;

        return (
          <FormItem
            key={currentStepName}
            label={label}
            largeLabel
            labelDisplayBlock
            {...rest}
            // @ts-ignore
            name={['metadata', 'respondent', formItemName]}
          >
            <RadioGroup
              options={parsedOptions}
              onClick={createFormItemOptionClickHandler(currentStepName)}
            />
            </FormItem>
        );
      }
      // case 'competitor': {
      //   if (!currentCategoryConfig) {
      //     return null;
      //   }
      //   let options = currentCategoryConfig?.options;
      //
      //   let parsedOptions: { label: string; value: string }[];
      //   if (!options || !Array.isArray(options)) {
      //     return null;
      //   } else {
      //     try {
      //       parsedOptions = options.map(option => {
      //         const { label } = JSON.parse(option);
      //         return { label, value: option };
      //       }) as { label: string; value: string }[];
      //     } catch (err) {
      //       return null;
      //     }
      //   }
      //
      //   return (
      //     <FormItem
      //       key={currentStepName}
      //       label={populateProjectValueToString(currentCategoryConfig?.title)}
      //       largeLabel
      //       labelDisplayBlock
      //       {...rest}
      //     >
      //       <RadioGroup
      //         options={parsedOptions}
      //         onClick={createFormItemOptionClickHandler(currentStepName)}
      //       />
      //       </FormItem>
      //   );
      // }
      case 'socialMedia': {
        if (!currentItemCategoryConfig) {
          return null;
        }
        let options = currentItemCategoryConfig?.options;
        if (!options || !Array.isArray(options)) {
          return null;
        }

        const label = currentCategoryStepSpellCheckedConfig?.title || t(currentItemCategoryConfig?.title);

        return (
          <FormItem
            key={currentStepName}
            label={label}
            subTitle={t(currentItemCategoryConfig?.subTitle)}
            largeLabel
            labelDisplayBlock
            {...rest}
          >
              <CheckboxGroup
                options={renderOptionsWithTranslate(options)}
              />
          </FormItem>
        );
      }
      case 'price.low':
      case 'price.high':
      case 'price.midHigh':
      case 'price.midLow': {
        // const highlightType = currentStepConfig.type as HighlightType;
        const spellcheckedBody = currentCategoryStepSpellCheckedConfig?.body
          || currentStepSpellCheckedConfig?.body;

        const bodySource = spellcheckedBody || currentItemCategoryConfig?.body || currentStepConfig.body;
        let intro = currentStepSpellCheckedConfig?.intro || currentStepConfig.intro;
        const isIntroArray = Array.isArray(intro);

        const productQuantity = project?.item.quantity;

        let formattedQuantity: string | undefined;
        if (productQuantity?.amount && productQuantity?.measurement) {
          const translationKey = `surveyConfig.quantity.${productQuantity.measurement}`;
          try {
            const translatedMeasurement = t(translationKey);
            formattedQuantity = `[item.name] ${productQuantity.amount} ${translatedMeasurement}`;

            if (isUseGolfConfig) {
              formattedQuantity = `${productQuantity?.amount} ${productQuantity?.measurement} [item.name]`;
            }
          } catch (err) {
            console.debug('Error translating quantity: ', err);
          }
        }

        if (isIntroArray) {
          const chunks = intro.map((introChunk: string) => {
            return <>{introChunk} <br /></>;
          });
          intro = <>{chunks}</>;
        }

        if (intro && !isIntroArray) {
          let translatedIntro = intro;

          if (intro.startsWith('surveyConfig')) {
            translatedIntro = t(intro);
          }

          if (formattedQuantity) {
            translatedIntro = translatedIntro.replace('[item.name]', formattedQuantity);
          }

          intro = populateProjectValueToString(translatedIntro);
        }

        const body = bodySource?.map((bodyItem: string, index: number) => {
          let translatedBodyItem = bodyItem;

          if (bodyItem.startsWith('surveyConfig')) {
            translatedBodyItem = t(bodyItem);
          }

          if (formattedQuantity) {
            translatedBodyItem = translatedBodyItem?.replace('[item.name]', formattedQuantity);
          }

          const normalizedBodyItem = populateProjectValueToString(translatedBodyItem);
          const result = highlightSpecialPhrase(normalizedBodyItem);

          return (
            <StyledSurveyStepParagraph key={`${currentStepName}-paragraph-${index}`}>
              {result}
            </StyledSurveyStepParagraph>
          );
        });
        const priceObject = project?.item.price;
        const paymentModel = priceObject?.paymentModel;

        const wtpPrefix = priceObject?.wtpPrefix || priceObject?.currency;
        let wtpPostfix = priceObject?.wtpPostfix;

        if (!wtpPostfix && paymentModel) {
          let paymentMethodLabel = paymentModel;

          try {
            const parsedPaymentMethod = JSON.parse(paymentModel);
            const isPerUnitSold = parsedPaymentMethod.value === 'per_unit_sold';
            const isMeasurementDozen = productQuantity?.measurement === 'dozen';

            if (isPerUnitSold && isMeasurementDozen) {
              const { /*amount,*/ measurement } = productQuantity;
              const translationKey = `surveyConfig.quantity.${measurement}`;
              const translatedMeasurement = t(translationKey);
              const perTranslated = t('surveyConfig.quantity.per');

              paymentMethodLabel = `${perTranslated} ${translatedMeasurement}`;
              // paymentMethodLabel = `${perTranslated} ${amount} ${translatedMeasurement}`;
            } else {
              const isUseConsolidatedPaymentModels = project?.isProvisioned;

              if (isUseConsolidatedPaymentModels) {
                const translatedPaymentMethod = t(`surveyConfig.paymentModels.${parsedPaymentMethod.value}`);
                paymentMethodLabel = translatedPaymentMethod ?? paymentMethodLabel;
              } else {
                // @ts-ignore
                paymentMethodLabel = t(`surveyConfig.${project?.type}Steps.paymentMethod.categories.${currentItemCategory}.options.${parsedPaymentMethod.value}`);
              }
            }
          } catch (err) {
            console.log('err', err);
          }

          if (paymentMethodLabel) {
            wtpPostfix = paymentMethodLabel;
          }
        }

        return (
          <StyledSurveyStepPriceContentWrapper key={currentStepName}>
            {intro && <StyledSurveyStepParagraph>{intro}</StyledSurveyStepParagraph>}

            {body}
            <FormItem
              {...rest}
              normalize={(value) => {
                if (value === null) {
                  return undefined;
                }

                return value;
              }}
            >
              <InputNumber
                type="number"
                controls={false}
                addonBefore={wtpPrefix}
                addonAfter={wtpPostfix}
                style={{ width: 300 }}
              />
            </FormItem>
          </StyledSurveyStepPriceContentWrapper>
        );
      }
      default: {
        const bodyContent = currentStepSpellCheckedConfig?.body || currentStepConfig?.body;
        if (bodyContent) {
          return composeBodySurveyFormItem({
            bodyContent,
            populateProjectValueToString,
            currentStepName,
            t
          });
        }

        let options = currentItemCategoryConfig?.options;

        if (options && Array.isArray(options)) {
          return composeRadioListSurveyFormItem({
            formValues,
            multiselect: currentStepConfig.multiselect,
            maxSelected: currentStepConfig.maxSelected,
            options,
            populateProjectValueToString,
            skipOptionsTranslate: isUseGolfConfig,
            renderOptionsWithTranslate,
            project,
            currentStepName,
            currentItemCategory,
            currentCategoryStepSpellCheckedConfig,
            currentItemCategoryConfig,
            t,
            createFormItemOptionClickHandler,
            ...rest
          })
        }

        return null;
      }
    }
  }, [
    formValues,
    surveyStepsConfig,
    projectSurveyConfig?.steps,
    project,
    currentStep,
    populateProjectValueToString,
    t,
    rest,
    renderOptionsWithTranslate,
    createFormItemOptionClickHandler,
    isUseGolfConfig,
    regions,
    incomeProfilingQuestion,
  ]);
};
