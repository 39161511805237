import type { Dispatch, FC, SetStateAction } from 'react';
import { useCallback, useMemo, useRef } from 'react';
import { Form, FormInstance } from 'antd';
import type { SurveyFormPartialType } from './SurveyForm.types';
import { handleSurveyFormChange } from './helpers/handleSurveyFormChange';
import type { Project } from '../../../types/project';
import { CategorySurveyConfig } from '../../../constants/surveyConfig';
import { RenderSurveyStep } from './helpers/RenderSurveyStep';
import { FormItemProps } from '../../molecules/FormItem/FormItem.types';
import { Button } from '../../atoms/Button';
import { StyledSurveyFormActions } from './SurveyForm.styles';
import { SurveyFormSuccess } from '../../molecules/SurveyFormSuccess';
import { populateObjectValue } from '../../../helpers/populateObjectValue';
import { shuffleArray } from '../../../helpers/shuffleArray';
import { StepsSequenceType } from '../../pages/Survey/Survey.types';
import { validateAddProjectFormCurrentStepFields } from './helpers/validateAddProjectFormCurrentStepFields';
import { useTranslation } from 'react-i18next';
import type { GolfCategorySurveyConfig } from '../../../constants/customSurveyConfigs/golfSuveyConfig';
import { CINTRegion, ProfilingQuestion } from '../../../types/cint';


interface SurveyFormProps {
  project?: Project;
  currentCategorySurveyConfig?: CategorySurveyConfig | GolfCategorySurveyConfig;
  projectSurveyConfig?: CategorySurveyConfig;
  formValues: SurveyFormPartialType;
  setFormValues: Dispatch<SetStateAction<SurveyFormPartialType>>;
  onSelectedOptionIndexChange: (formItemName: string, index: number) => void;
  // TODO: Fix possible recursive import
  currentStepName?: StepsSequenceType[number] | string;
  handlePreviousStep: () => void;
  handleNextStep: (isDisqualified?: boolean) => void;
  step: number;
  isLastStep: boolean;
  isDisqualified: boolean;
  setIsDisqualified: Dispatch<SetStateAction<boolean>>;
  handleFinish: () => void;
  isUseGolfConfig?: boolean;
  regions?: CINTRegion[];
  incomeProfilingQuestion?: ProfilingQuestion;
}

export const SurveyForm: FC<SurveyFormProps> = ({
  project,
  currentCategorySurveyConfig,
  projectSurveyConfig,
  setFormValues,
  formValues,
  onSelectedOptionIndexChange,
  isLastStep,
  currentStepName,
  handlePreviousStep,
  handleNextStep,
  step,
  isDisqualified,
  setIsDisqualified,
  handleFinish,
  isUseGolfConfig,
  regions,
  incomeProfilingQuestion,
}) => {
  const formRef = useRef<FormInstance<SurveyFormPartialType> | null>(null);
  const { t } = useTranslation();

  const shuffledSurveyConfig = useMemo(() => {
    const blacklistedSteps = ['isInterested', 'usageFrequency', 'socialMedia'];
    const currentItemCategory = project?.item?.category;

    return currentCategorySurveyConfig?.steps.map(stepConfig => {
      const isStepBlacklisted = blacklistedSteps.includes(stepConfig.step);

      if (currentItemCategory && !isStepBlacklisted && 'categories' in stepConfig && stepConfig.categories) {
        type CategoryKey = keyof typeof stepConfig.categories;

        const currentCategoryConfig = stepConfig.categories[currentItemCategory as CategoryKey];

        if (currentCategoryConfig && 'options' in currentCategoryConfig) {
          let options = currentCategoryConfig['options'];

          if (typeof currentCategoryConfig['options'] === 'string') {
            options = populateObjectValue(currentCategoryConfig['options'], project);
          }

          if (Array.isArray(options)) {
            const shouldSkipShuffle = 'skipOptionsShuffle' in stepConfig && stepConfig.skipOptionsShuffle;
            let shuffledOptions = options.map((option) => {
              if (typeof option === 'string') {
                try {
                  return JSON.parse(option);
                } catch (err) {
                  console.debug(err);
                }
              }

              return option;
            });

            if (!shouldSkipShuffle) {
              shuffledOptions = shuffleArray(shuffledOptions);
            }

            if ('defaultOptions' in currentCategoryConfig) {
              const defaultOptions = currentCategoryConfig.defaultOptions ?? [];

              shuffledOptions.push(...defaultOptions);
            }

            return {
              ...stepConfig,
              categories: {
                [currentItemCategory]: {
                  // @ts-ignore
                  ...stepConfig.categories[currentItemCategory],
                  options: shuffledOptions
                }
              }
            }
          }
        }
      }

      return stepConfig;
    });
  }, [currentCategorySurveyConfig?.steps, project]);

  const currentStepFormConfig = useMemo(() => {
    if (!project || !currentStepName || !shuffledSurveyConfig) {
      return null;
    }

    // @ts-ignore
    const currentStepConfig = shuffledSurveyConfig.find(configItem => configItem.step === currentStepName);
    const itemCategory = project.item.category;

    // @ts-ignore
    return currentStepConfig?.categories?.[itemCategory];
  }, [currentStepName, project, shuffledSurveyConfig]);

  const onFormValuesChange = useCallback((changedFields: Record<string, string | Record<string, string>>) => {
    if (currentStepFormConfig?.options && Array.isArray(currentStepFormConfig.options)) {
      const [value] = Object.values(changedFields);
      if (typeof value === 'string') {
        const selectedOption = currentStepFormConfig?.options?.find(
          (option: {
            label: string;
            value: string;
            disqualify?: boolean
          }) => option.value === value
        );
        if (selectedOption) {
          setIsDisqualified(!!selectedOption.disqualify);
        }
      }
      // Multiselect
      if (Array.isArray(value)) {
        const selectedOptions = currentStepFormConfig?.options?.filter((option: {
          value: string;
        }) => value.includes(option.value));
        const isDisqualifyFieldSelected = selectedOptions.some((option: { disqualify: boolean; }) => option.disqualify);
        setIsDisqualified(isDisqualifyFieldSelected);
      }
    }

    handleSurveyFormChange(changedFields, setFormValues);
  }, [currentStepFormConfig, setFormValues, setIsDisqualified]);

  const createFormItemOptionClickHandler = useCallback((formItemName: string) => {
    return (index: number) => {
      onSelectedOptionIndexChange(formItemName, index);
    }

  }, [onSelectedOptionIndexChange]);

  const translatedProject = useMemo(() => {
    if (!project) {
      return null
    }

    return {
      ...project,
      item: {
        ...project.item,
        name: project.item.translatedName || project.item.name,
        description: project.item.translatedDescription || project.item.description
      }
    }
  }, [project])

  const renderStepContent = useMemo(() => {
    if (!translatedProject) {
      return null;
    }

    let stepFormItemProps: FormItemProps = { name: currentStepName };

    const separator = '.';

    if (currentStepName?.includes(separator)) {
      stepFormItemProps.name = currentStepName.split(separator);
    }

    return <RenderSurveyStep
      project={translatedProject}
      currentStep={currentStepName}
      surveyStepsConfig={shuffledSurveyConfig}
      createFormItemOptionClickHandler={createFormItemOptionClickHandler}
      projectSurveyConfig={projectSurveyConfig}
      isUseGolfConfig={isUseGolfConfig}
      formValues={formValues}
      regions={regions}
      incomeProfilingQuestion={incomeProfilingQuestion}
      {...stepFormItemProps}
    />
  }, [
    translatedProject,
    currentStepName,
    shuffledSurveyConfig,
    createFormItemOptionClickHandler,
    projectSurveyConfig,
    isUseGolfConfig,
    formValues,
    regions,
    incomeProfilingQuestion,
  ]);

  const isNextButtonDisabled = useMemo(() => {
    const isPriceQuestion = currentStepName?.startsWith('price');
    const shouldValidate = isPriceQuestion || (currentStepFormConfig && 'options' in currentStepFormConfig);
    if (!shouldValidate) {
      return false;
    }

    return !validateAddProjectFormCurrentStepFields(formValues, currentStepName);
  }, [currentStepFormConfig, currentStepName, formValues]);
  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <Form
      requiredMark={false}
      layout="vertical"
      onValuesChange={onFormValuesChange}
      ref={formRef}
    >
      <div ref={containerRef}>
        {!isLastStep ? (
          renderStepContent
        ) : (
          <SurveyFormSuccess
            parentRef={containerRef}
            isDisqualified={isDisqualified}
          />
        )}
        <StyledSurveyFormActions>
          {step === 1 || isLastStep ? (
            <div style={{ minWidth: 110 }} />
          ) : (
            <Button
              variant="tertiary"
              style={{ minWidth: 110 }}
              onClick={handlePreviousStep}
            >{t('button.back')}</Button>
          )}

          {!isLastStep ? (
            <Button
              type="primary"
              htmlType="button"
              style={{ minWidth: 110 }}
              onClick={() => handleNextStep(isDisqualified)}
              disabled={isNextButtonDisabled}
            >
              {t('button.next')}
            </Button>
          ) : null}
        </StyledSurveyFormActions>

        {isLastStep ? (
          <Button
            type="primary"
            htmlType="button"
            onClick={handleFinish}
            style={{ minWidth: 120, marginInline: 'auto' }}
          >
            {t('button.finish')}
          </Button>
        ) : null}
      </div>
    </Form>
  );
};
