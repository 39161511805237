import axios from 'axios';
import { CINTRegion, ProfilingQuestion } from '../types/cint';


export const checkIfRespondentIsEligibleToSurvey = async (projectId: string, respondentId: string): Promise<{
  isEligible: boolean
}> => {
  const url = `/survey/${projectId}/respondent/${respondentId}/verification`;

  return await axios.get(url);
};

export const getRegionsPublic = async (countryId: number, regionTypeId: number): Promise<CINTRegion[]> => {
  const url = '/cint/regions/public';

  const params = { countryId, regionTypeId };

  return await axios.get(url, { params });
};

export const getProfilingQuestionsPublic = async (countryId: number): Promise<{
  incomeProfilingQuestion: ProfilingQuestion
}> => {
  const url = '/cint/profiling-questions/public';

  const params = { countryId };

  return axios.get(url, { params });
};
