import { Result } from 'antd';
import type { FC } from 'react';
import { SmileOutlined } from '@ant-design/icons';


const SurveyExpired: FC = () => {
  return (
    <Result
      icon={<SmileOutlined />}
      title="The survey is already finished"
      subTitle="Aplologies, the survey is no longer available. Thank you for your effort 🎉"
    />
  );
};

export default SurveyExpired;
